.input_container {
  margin-bottom: 10px;
}

.input_container label {
  margin: 0;
  margin-bottom: 5px;
}

.input_container_area {
  position: relative;
  display: flex;
}

.input_area {
  height: 40px !important;
  cursor: auto;
  padding-right: 30px;
  /* background-color: greenyellow; */
}

.input_container_mid textarea {
  height: 104px;
}

.input_container .input_container_select {
  display: flex;
  justify-content: space-between;
  cursor: default;
  /* flex-wrap: wrap; */
  /* background-color: red; */
}

.input_container_mid textarea:hover {
  cursor: pointer;
}

.input_container textarea {
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 6px 10px;
  height: 180px;
  resize: none;
}

.input_container textarea:focus {
  outline: none;
}

.input_container_mid textarea {
  height: 104px;
}

.input_container_mid textarea:hover {
  cursor: pointer;
}

.attach_svg {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 11;
  height: 24px;
  width: 24px;
  /* background-color: greenyellow; */
}

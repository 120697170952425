.container {
  height: 100%;
  /* width: 100%; */
}

.input_container {
  margin-bottom: 10px;
  /* width: 100%; */
}

.input_container label {
  margin: 0;
  margin-bottom: 5px;


  /* color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal; */
}

.input_container input {
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 6px 10px;
}

.input_container input:focus {
  outline: none;
}

.input_container select {
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 8px 10px;
}

.input_container select:focus,
select:active {
  outline: none;
  outline: none !important;
  box-shadow: none;
}

.input_container textarea {
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 8px 10px;
  height: 165px;
  resize: none;
}

.input_container textarea:focus {
  outline: none;
}

.input_container_mid textarea {
  height: 104px;
}

.upload_box .upload_div {
  border-radius: 4px;
  position: relative;
  border:1px dashed #808080;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
}

.upload_div input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.update_portfolio {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service_image {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploaded_img_container {
  overflow: auto;
  overflow-y: hidden;
}

.uploaded_img_container .singleImage {
  position: relative;
}

.drag_img{
  color: #5887FF;
}

.uploaded_img_container img {
  width: 100px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 5px;
}

.uploaded_img_container svg {
  width: 25px;
  position: absolute;
  right: 3px;
  top: 3px;
  background-color: red;
  border-radius: 50px;
  padding: 5px;
  cursor: pointer;
}

.button {
  padding-bottom: 30px;
}

button {
  border: 0;
  font-size: 20px;
}
.container {
  height: 100%;
}

.input_container {
  margin-bottom: 10px;
}

.input_container label {
  margin: 0;
  margin-bottom: 5px;


  /* color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal; */
}

.input_container input {
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 6px 10px;
}

.input_container input:focus {
  outline: none;
}

.input_container select {
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 6px 10px;
}

.input_container select:focus,
select:active {
  outline: none;
  outline: none !important;
  box-shadow: none;
}

.input_container textarea {
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 6px 10px;
  height: 120px;
  resize: none;
}

.input_container textarea:focus {
  outline: none;
}

.drag {
  color: #5887ff;
}

.button {
  padding-bottom: 30px;
}

button {
  border: 0;
  font-size: 20px;
}

.heading {
  font-size: min(50px, 8vw);
  color: white;
  font-weight: 700;
  display: none;
}

@media screen and (min-height: 860px) {
  .heading {
    display: block;
  }
}

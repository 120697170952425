.container {
  height: 100%;
}

.input_container {
  margin-bottom: 10px;
}

.input_container label {
  margin: 0;
  margin-bottom: 5px;


  /* color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal; */
}

.input_container input {
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 6px 10px;
}

.input_container input:focus {
  outline: none;
}

.input_container input:focus {
  outline: none;
}

.input_container select {
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 6px 10px;
}

.input_container select:focus,
select:active {
  outline: none;
  outline: none !important;
  box-shadow: none;
}

.input_container textarea {
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 6px 10px;
  height: 120px;
  resize: none;
}

.input_container textarea:focus {
  outline: none;
}

.update_propertyMagazine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* .input_container_mid textarea {
  height: 80px;
} */

.choose_pdf {
  color: #5887ff;
}

.upload_box .upload_div {
  border-radius: 4px;
  position: relative;
  border: 1px dashed #808080;
  height: 145px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
}

.profession_image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload_div input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.uploaded_img_container {
  overflow: auto;
  overflow-y: hidden;
}

.uploaded_img_container .singleImage {
  position: relative;
}

.uploaded_img_container img {
  width: 100px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 5px;
}

.uploaded_img_container svg {
  width: 25px;
  position: absolute;
  right: 3px;
  top: 3px;
  background-color: red;
  border-radius: 50px;
  padding: 5px;
  cursor: pointer;
}

.professions_sec textarea {
  height: 150px;
}

.professions_sec .upload_div {
  height: 150px;
}

button {
  border: 0;
  font-size: 20px;
}

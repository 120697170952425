.img_link {
  text-decoration-line: none;
  color: black;
}

.img {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  margin-right: 5px;
}

.table {
  height: 52vh;
  padding: 20;
  min-width: 992px;
}

.container {
  margin: 0;
  padding: 0;
  /* background-color: pink; */
}

.container img {
  width: 93px;
  height: 93px;
  /* background-color: skyblue; */
}

.container .profile {
  margin: 0;
  padding: 0;
  margin-left: 22px;
  /* background-color: pink; */
}

.container .greet {
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-weight: 500;
  font-family: sans-serif;
  font-style: normal;
  line-height: normal;
  color: #757d8a;
  /* background: orange; */
}

.container .name {
  margin: 0;
  padding: 0;
  margin-top: 6px;
  color: #0d0d0d;
  font-size: 48px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  /* background-color: yellow; */
}

.container .toggle_switch {
  gap: 20px;
}

.container .svg {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.enquiry_link {
  color: white;
  padding: 8px 12px;
  border: 0;
  font-weight: 400;
  /* margin-Left: 40px; */
}

.insights {
  text-align: left;
  margin-left: 5px;
}

.date {
  font-size: 14px;
  color: #757d8a;
}

/* .today {
} */

.live {
  font-weight: 500;
  margin-bottom: 5px;
}

.header_footer {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal_class {
  height:max-content;
  width: 60%;
  padding: 10px 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background-color: white;
}

.modal_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}


/* input[type='file'] {
  opacity:0    
} */
.dashboard {
  background-color: rgb(255, 255, 255);
  height: 100vh;
  /* background-color: pink; */
}

.store_name {
  margin-top: 40px;
  color: #0d0d0d;
  display: flex;
  align-items: center;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 10%;
  height: 24px;
  background-color: #f1f2f6;
  border-radius: 4px;
}

.dashboard_container {
  margin: 30px;
}

.btn {
  display: flex;
  margin: 20px 0px;
}

.button {
  padding: 13px 44px;
  min-width: 200px;
  border-radius: 4px;
  background: #2461ff;
  text-align: center;
  margin-right: 20px;
  color: white;
  text-decoration: none;
}

.input_container {
  margin-bottom: 10px;
  margin-top: 20px;
}

.upload_box .upload_div {
  border-radius: 4px;
  position: relative;
  border: 1px dashed #808080;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
}

.upload_div input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
}

.property_image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.uploaded_img_container {
  overflow: auto;
  overflow-y: hidden;
}

.uploaded_img_container .singleImage {
  position: relative;
}

.uploaded_img_container img {
  width: 100px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 5px;
}

.uploaded_img_container svg {
  width: 25px;
  position: absolute;
  right: 3px;
  top: 3px;
  background-color: red;
  border-radius: 50px;
  padding: 5px;
  cursor: pointer;
}

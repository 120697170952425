.icon {
  cursor: pointer;
  display: flex;
  border-radius: 50%;
}

.icon:hover {
  background-color: rgb(216, 216, 216);
  transition: all 0.2s ease-in-out;
}

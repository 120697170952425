.search_container {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 992px;
  align-items: center;
  border-radius: 6px 6px 0px 0px;
  margin-bottom: 5px;
}

.search_bar {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  width: 300px;
  height: 36px;
  flex-shrink: 0;
  border: none;
  outline: none;
  border-radius: 10px;
  border: 1px solid #000;
}
.search {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: white;
  cursor: pointer;
  width: 30px;
}

.search_input {
  border-right: none;
  color: #000;
  font-style: normal;
  font-weight: 400;
  border: none;
  outline: none !important;
  line-height: normal;
}

.search_btn {
  margin: 0;
  height: 100%;
  padding: 0;
  border-left: 2px solid black;
  display: flex;
  align-items: center;
  color: #000;
  background-color: white;
  font-size: 16px;
  line-height: normal;
  margin-bottom: 5px;
  padding-left: 5px;
  /* background-color: pink; */
}

.options_link {
  /* Light/Basic Typography/Paragraph */

  /* background-color: #000; */
  font-feature-settings: "clig" off, "liga" off;
  padding: 20px;
  color: #8b909a;
  font-family: sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration: none;

  font-feature-settings: "clig" off, "liga" off;

  /* Light/Basic Typography/Paragraph */
}

.active_link {
  /* background-color: #000; */
  padding: 20px;
  color: #0f60ff;
  font-family: sans-serif;
  text-decoration: underline 1.5px solid #0f60ff;
  padding-top: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  /* Light/Basic Typography/Paragraph */
}

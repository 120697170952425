.table {
  height: 52vh;
  width: 100%;
  min-width: 992px;
}

.link {
  text-decoration: none;
  color: black;
}

.table_control {
  overflow: auto;
}

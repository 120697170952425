.icon {
  width: 20px;
  margin: 0px 5px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: grey; */
  padding: 30px;
  border-radius: 10px;
  border: 0px solid #898989;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  width: 30%;
}

* {
  padding: 0;
  margin: 0;
}

select{
  cursor: pointer;
}

.ag-theme-alpine {
  --ag-borders: none;
  --ag-row-border-width: 0px !important;
  --ag-row-border-color: #f0f0f0 !important;
}

.ag-row-even {
  background-color: #f6f6f6 !important
}

.ag-cell {
  display: flex !important;
  align-items: center !important;
}
.container {
  color: #757d8a;
  background-color: rgb(241, 242, 246);
  position: relative;
  height: 100vh;
}

.logo_container {
  margin-top: 50px;
}
.toggle_size {
  border-radius: 4px;
  color: rgba(37, 37, 37, 0.37);
  height: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 25px;
  position: absolute;
  top: 50%;
  right: -12px;
  cursor: pointer;
}

.toggle_size:hover {
  color: rgb(85, 85, 84);
}

.item_list {
  display: grid;
  grid-template-columns: auto;
  gap: 15px;
}

.single_menu_btn {
  background-color: rgb(241, 242, 246);
  cursor: pointer;
  text-decoration: none;
  padding: 10px 15px;
  color: #757d8a;
}
.contracted_sidebar_menu_item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.contracted_sidebar_menu_item:hover {
  background-color: rgb(255, 255, 255);
}
.single_menu_btn:hover {
  cursor: pointer;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
}

.item_icon {
  margin-right: 10px;
  display: flex;
}

.item_title {
  font-size: 14px;
}

.sidebar_footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}
.contracted_sidebar_logout {
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.contracted_sidebar_logout:hover {
  background-color: rgb(255, 255, 255);
}
.down_profile {
  background-color: green;
  height: 28px;
  width: 28px;
  border-radius: 50px;
  margin-right: 5px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .conent {
  background-color: orange;
} */

.heading {
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 500;
  /* width: 454px; */
  height: 49px;
}

.form_content {
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 45px;
  width: 100%;
}
.label {
  color: #000;
  font-size: 16px;
  /* font-weight: 400; */
}
.input {
  border-radius: 8px;
  border: 1px solid #1b4693;
  background: #fff;
  height: 54px;
  margin-top: 15px;
  padding-left: 20px;
}
.input:focus {
  outline: none;
}

.error_content {
  border: 1px solid red;
}
.btn_container {
  display: flex;
  justify-content: center;
}
.btn {
  width: 232px;
  height: 49px;
  flex-shrink: 0;
  border-radius: 36px;
  background: #1b4693;
  border: 0;
  color: white;
  margin-top: 36px;
}
.switch_form {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 36px;
}

.switch_form h3 {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  width: 232px;
}

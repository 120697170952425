.form_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 300px;
  margin-bottom: 24px;
}

.input {
  border-radius: 16px;
  border: 1px solid #1b4693;
  background: #fff;
  /* width: 435px; */
  height: 50px;
  margin-top: 10px;
  padding-left: 10px;
}

.input:focus {
  outline: none;
}

input[type='file'] {
  opacity:0    
}

.select_city {
  margin-top: 10px;
  /* width: 350px; */
  border-radius: 16px;
  border: 1px solid #1b4693;
  background: #fff;
  padding: 10px;
  cursor: pointer;

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 10px;
}

.select_city:focus {
  outline: none;
}

.options_select {
  width: 100%;
}

.textarea {
  width: 435px;
  height: 178px;
  flex-shrink: 0;
  border-radius: 16px;
  /* border: 1px solid #; */
  background: #fff;
  padding: 10px;
}
.btn_create_account {
  width: 232px;
  height: 49px;
  margin-top: 20px;
  border-radius: 36px;
  background: #1b4693;
  border: 0;
  color: white;
  cursor: pointer;
}

.upper_button {
  display: flex;
  justify-content: center;
}

.container {
  width: 329px;
  height: 59px;
  border-radius: 33px;
  background: #dde4f8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 52px;
}

.btn {
  border-radius: 33px;
  /* background: #1b4693; */
  width: 146px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn_active {
  background: #1b4693;
}


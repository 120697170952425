.search_container {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  min-width: 992px;
  align-items: center;
  border-radius: 6px 6px 0px 0px;
  margin-bottom: 5px;
  gap: 30px;
}

.search_bar {
  display: flex;
  justify-content: space-around;
  width: 300px;
  height: 36px;
  flex-shrink: 0;
  border: none;
  outline: none;
  border-radius: 10px;
  border: 1px solid #000;
}
.search {
  border: none;
  outline: none;
  background-color: white;
  cursor: pointer;
  width: 30px;
}

.search_input {
  border-right: none;
  color: #000;
  font-style: normal;
  font-weight: 400;
  border: none;
  outline: none;
  line-height: normal;
}

.search_btn {
  height: 100%;
  border-left: 2px solid black;
  display: flex;
  align-items: center;
  color: #000;
  background-color: white;
  font-size: 16px;
  line-height: normal;
  padding-left: 5px;
}
.process_options {
  display: flex;
  gap: 15px;
  align-items: center;
}

.options_link {
  padding: 10px;
  color: #8b909a;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.active_link {
  text-decoration: underline 1.5px solid #0f60ff;
  text-decoration: none;
  background-color: #ddf5ff;
}

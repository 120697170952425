.input_container {
  margin-bottom: 10px;
  position: relative;
}

.input_container label {
  margin: 0;
  margin-bottom: 8px;
}

.input_container textarea {
  height: 40px !important;
  /* background-color: blue; */
}

.input_container input {
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 8px 10px;
  background-color: black;
}

.input_container input:focus {
  outline: none;
}

.input_container .tag_input {
  position: absolute;
  z-index: 99;
  width: inherit;
  background-color: #f8f9fa !important;
}

.tags_pill {
  display: flex;
  flex-wrap: wrap;
  gap: 10px !important;
}

.pill {
  background-color: blue;
  color: white;
  border-radius: 10px;
  padding: 0px 5px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pill p {
  margin: 0px;
  padding: 0px;
}

.pill svg {
  width: 20px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
